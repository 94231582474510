import { useState } from 'react'
import clsx from 'clsx'
import styles from './Footer.module.scss'
import { showInfoMessage } from '../toastMessage'
import disableDevtool from'disable-devtool';
disableDevtool();
function Footer() {
    const [valueSubmid, setValueSubmit] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        if (valueSubmid === '') {
            showInfoMessage('Chưa nhập lời nhắn!')
            return
        }
        const subject = encodeURIComponent('Xin chào tôi đến từ XemGì!')
        const body = encodeURIComponent(valueSubmid)
        window.location.href = `lxvosong2@gmail.com?subject=${subject}&body=${body}`
    }
    
   return (
        <footer>
            <div className={styles.footer__row}>
                <div className={styles.footer__column}>
                    <h3>Thông Tin</h3>
                    <p>XemGì - APP đem đến trải nghiệm xem phim mượt mà, không quảng cáo làm phiền. Tất cả nội dung của trang web này đều được giả lập tìm kiếm và thu thập ở các trang web trên Internet ophim ,kkphim không  lưu chữ bất kỳ hình ảnh hay video nào trên hệ thống cũng như không cung cấp phát trực tuyến chính hãng.</p>
                </div>
                <div className={styles.footer__column}>
                    <h3>Mạng xã hội</h3>
                    <ul>
                        <li>
                            <a title='XemGì trên Facebook' href="https://www.facebook.com/" target="_blank">
                                <i className="fa-brands fa-square-facebook"></i>
                                <span>Facebook</span>
                            </a>

                        </li>
                        <li>
                            <a title='XemGì trên Instagram' href="https://www.instagram.com/" target="_blank">
                                <i className="fa-brands fa-square-instagram"></i>
                                <span>Instagram</span>
                            </a>
                        </li>
                        
                        <li>
                            <a title='XemGì trên Tiktok' href="https://www.tiktok.com/" target="_blank">
                                <i className="fa-brands fa-tiktok"></i>
                                <span>Tiktok</span>
                            </a>
                        </li>
                        <li>
                            <a title='XemGì trên Telegram' href="https://t.me/" target="_blank">
                                <i className="fa-brands fa-telegram"></i>
                                <span>Telegram</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.footer__row}>
                <p className={styles.footer__copyright}>© 2024 - XemGì. APP TEST thử nghiệm giả lập tìm kiếm xem phim chất lượng, không quảng cáo làm phiền.</p>
            </div>
            <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool'></script>
        </footer>
    )
}

export default Footer