import { useEffect } from "react"
import Movies from '../../components/Layout/components/Movies'
import Movies1 from '../../components1/Layout/components/Movies'
import Slides from '../../components/Layout/components/Slides'

function Home() {
    useEffect(() => {
        document.title = 'XemGì Thưởng Thức Những Bộ Phim FULL HD Không Quảng Cáo.'
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <>
            <Slides api='https://ophim1.com/danh-sach/phim-moi-cap-nhat?page=1' />
        <center>APP XEMGI.CC APP Xem Phim Siêu Nhanh Siêu Mượt Không ADS.Với 2 SERVER Phim UPDATE Cập Nhật Liên Tục Nhanh Nhất cả 2 đều cho trải nghiệm siêu mượt.</center>
            <Movies api="https://ophim1.com/v1/api/danh-sach/phim-bo?page=1&limit=18" />
            <Movies1 api="https://phimapi.com/v1/api/danh-sach/phim-bo?page=1&limit=18" />
            <Movies api="https://ophim1.com/v1/api/danh-sach/phim-le?page=1&limit=18" />
            <Movies api="https://ophim1.com/v1/api/danh-sach/hoat-hinh?page=1&limit=18" />
            <Movies api="https://ophim1.com/v1/api/danh-sach/tv-shows?page=1&limit=18" />
        </>
    )
}

export default Home
